import React, {useEffect, useRef, useState} from 'react'

export const PortfolioSVG = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    const styleA = {
        fill: 'rgb(45,106,52)',
        fillRule: 'nonzero'
    }

      return (

        <svg width="100%" height="100%" viewBox="0 0 1452 172" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle}>
            <g transform="matrix(1,0,0,1,-1054,-1079)">
                <g transform="matrix(1.01639,0,0,1.65383,582.555,-567.045)">
                    <g transform="matrix(157.377,0,-64.8367,149.114,438.756,1097.02)">
                        <path d="M0.061,-0.667L0.061,-0.469L0.12,-0.469L0.12,-0.444C0.12,-0.405 0.104,-0.389 0.066,-0.389L0.04,-0.389L0.04,-0.291C0.061,-0.29 0.072,-0.29 0.091,-0.29C0.188,-0.29 0.217,-0.298 0.24,-0.33C0.258,-0.355 0.264,-0.39 0.264,-0.473L0.264,-0.667L0.061,-0.667ZM0.349,-0.667L0.349,-0.469L0.408,-0.469L0.408,-0.444C0.408,-0.405 0.392,-0.389 0.354,-0.389L0.328,-0.389L0.328,-0.291C0.349,-0.29 0.36,-0.29 0.379,-0.29C0.476,-0.29 0.505,-0.298 0.528,-0.33C0.546,-0.355 0.552,-0.39 0.552,-0.473L0.552,-0.667L0.349,-0.667Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,533.969,1097.02)">
                        <path d="M0.047,-0L0.25,-0L0.25,-0.163L0.506,-0.163C0.65,-0.163 0.695,-0.167 0.74,-0.182C0.82,-0.211 0.847,-0.269 0.847,-0.417C0.847,-0.588 0.811,-0.642 0.685,-0.661C0.648,-0.666 0.63,-0.667 0.504,-0.667L0.047,-0.667L0.047,-0ZM0.25,-0.33L0.25,-0.5L0.504,-0.5C0.593,-0.499 0.595,-0.499 0.609,-0.494C0.635,-0.485 0.644,-0.465 0.644,-0.417C0.644,-0.371 0.638,-0.353 0.619,-0.341C0.602,-0.331 0.598,-0.331 0.504,-0.33L0.25,-0.33Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,670.414,1097.02)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,784.198,1097.02)">
                        <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.196C0.217,-0.269 0.222,-0.295 0.239,-0.318C0.256,-0.34 0.289,-0.352 0.329,-0.352C0.402,-0.352 0.422,-0.333 0.422,-0.263L0.589,-0.263L0.589,-0.295C0.589,-0.437 0.541,-0.485 0.398,-0.485C0.299,-0.485 0.249,-0.462 0.216,-0.402L0.207,-0.402L0.207,-0.473L0.04,-0.473Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,877.994,1097.02)">
                        <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,969.115,1097.02)">
                        <path d="M0,-0.346L0.091,-0.346L0.091,-0L0.268,-0L0.268,-0.346L0.442,-0.346L0.442,-0.473L0.268,-0.473C0.27,-0.547 0.287,-0.56 0.381,-0.56C0.392,-0.56 0.395,-0.56 0.428,-0.559L0.428,-0.676C0.39,-0.678 0.372,-0.679 0.339,-0.679C0.221,-0.679 0.176,-0.669 0.138,-0.634C0.102,-0.601 0.094,-0.574 0.091,-0.473L0,-0.473L0,-0.346Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,1037.57,1097.02)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,1151.36,1097.02)">
                        <rect x="0.04" y="-0.667" width="0.177" height="0.667" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,1191.8,1097.02)">
                        <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,1232.25,1097.02)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,1385.38,1097.02)">
                        <path d="M0.023,-0.221L0.023,-0.189C0.023,-0.097 0.038,-0.058 0.084,-0.029C0.131,0.001 0.212,0.01 0.419,0.01C0.618,0.01 0.702,0.005 0.742,-0.01C0.811,-0.036 0.839,-0.093 0.839,-0.209C0.839,-0.302 0.822,-0.347 0.776,-0.377C0.733,-0.404 0.69,-0.409 0.47,-0.416C0.329,-0.42 0.274,-0.423 0.256,-0.428C0.235,-0.433 0.226,-0.446 0.226,-0.471C0.226,-0.52 0.24,-0.524 0.412,-0.524C0.556,-0.524 0.581,-0.522 0.598,-0.512C0.613,-0.503 0.616,-0.494 0.618,-0.457L0.814,-0.457C0.815,-0.47 0.815,-0.484 0.815,-0.488C0.815,-0.567 0.799,-0.609 0.759,-0.636C0.712,-0.668 0.642,-0.677 0.448,-0.677C0.288,-0.677 0.196,-0.673 0.157,-0.665C0.065,-0.645 0.029,-0.588 0.029,-0.461C0.029,-0.314 0.075,-0.272 0.24,-0.267L0.294,-0.265L0.402,-0.26L0.562,-0.254C0.627,-0.253 0.642,-0.243 0.642,-0.203C0.642,-0.144 0.642,-0.144 0.424,-0.144C0.281,-0.144 0.259,-0.146 0.24,-0.158C0.224,-0.168 0.22,-0.181 0.22,-0.221L0.023,-0.221Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,1521.19,1097.02)">
                        <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,1561.64,1097.02)">
                        <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,1652.76,1097.02)">
                        <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleA} />
                    </g>
                    <g transform="matrix(157.377,0,-64.8367,149.114,1761.82,1097.02)">
                        <path d="M0.061,-0.667L0.061,-0.469L0.12,-0.469L0.12,-0.444C0.12,-0.405 0.104,-0.389 0.066,-0.389L0.04,-0.389L0.04,-0.291C0.061,-0.29 0.072,-0.29 0.091,-0.29C0.188,-0.29 0.217,-0.298 0.24,-0.33C0.258,-0.355 0.264,-0.39 0.264,-0.473L0.264,-0.667L0.061,-0.667ZM0.349,-0.667L0.349,-0.469L0.408,-0.469L0.408,-0.444C0.408,-0.405 0.392,-0.389 0.354,-0.389L0.328,-0.389L0.328,-0.291C0.349,-0.29 0.36,-0.29 0.379,-0.29C0.476,-0.29 0.505,-0.298 0.528,-0.33C0.546,-0.355 0.552,-0.39 0.552,-0.473L0.552,-0.667L0.349,-0.667Z" style={styleA} />
                    </g>
                </g>
            </g>
        </svg>

    )
}
