import React, {useEffect} from 'react'
import {settings} from '../storage'
import {WorkSVG} from '../svg/work'
import Menu from '../modules/menu'
import {ChartExplorerSVG} from '../svg/chartExplorer'
import {WeJaySVG} from '../svg/weJay'
import {PortfolioSVG} from '../svg/portfolio'
import epsilon from '../../public/epsilon.jpg'


export default function Work() {

  useEffect(() => {
    settings.color = '#EFA226'
  })



  return (
    <div className="app">
      <Menu static={true} page="work" />
      <div id="work" className="page_parent_container">
        <div id="work_bg" className="page_body_container">
          <div id='work_header_container'>
            <div id='work_svg_container' className="page_header" >
              <WorkSVG />
            </div>
            <div id="skills">
              <h2>Primary Production Credits</h2>
              <h3>Javascript • React • Redux • HTML5 • CSS • Express • PostgreSQL • Sequelize • Git • Procreate • Affinity Designer</h3>
              <br/>
              <h2>Secondary Production Credits</h2>
              <h3>React Native • SQL • Heroku • OAuth • Bash • Adobe Suite</h3>
            </div>
          </div>
          <div id="work_content">
            <div className="project_container">
              <div className="project_logo_container">
                <div id="chart_explorer_svg_container">
                  <ChartExplorerSVG />
                </div>
              </div>
              <div className="project_description">
                <br />
                  <p>
                  Chart Explorer is an interactive full stack web application that allows users to explore the history of the Billboard Hot 100 charts. Built with Spotify’s web API, users can listen to songs throughout history while saving their favorites to a dedicated Chart Explorer playlist. Additionally, users can utilize a birthday playlist feature that allows them to generate a playlist of all the #1 songs on any given birthday. All chart playlists can be saved to a user’s Spotify library. 

                  <br />
                  <br />

                  Tech Stack: React, PostgreSQL, Express, Nodes, Spotify API, GSAP

                  <br />
                  <br />

                  <a href="https://www.chartexplorer.us" target="_blank" >www.chartexplorer.us</a> 
                  <br />
                  <a href="https://www.youtube.com/watch?v=dpnbZeyj0u0" target="_blank" >Demo Video</a> 
                  </p>
              </div>
            </div>
            <div className="project_container">
              <div className="project_logo_container">
                <div id="weJay_svg_container">
                  <WeJaySVG />
                </div>
              </div>
              <div className="project_description">
                <br />
                  <p>
                  Built by a team of three, weJay is a collaborative mobile playlist application that allows multiple users to join a password protected playlist, add songs to it, and vote on the songs. As users vote a song up or down, the playlist order rearranges in real time, based on the voting preferences of the users.
                  <br />
                  <br />
                  Tech stack:  React Native, Google Cloud Firestore, Expo, Redux, and Spotify’s API
                  <br />
                  <br />
                  <a href="https://www.youtube.com/watch?v=2NvC6te1Nak&list=FLYJ9i1NdKuW4vzi0sjL91kA&index=7&t=2s" target="_blank">Demo Video</a>
                  <br />
                  <a href="https://github.com/weJayCapstone/weJay" target="_blank" >GitHub</a>
                  </p>
              </div>
            </div>
            <div className="project_container">
              <div className="project_logo_container">
                <div id="portfolio_svg_container">
                  <PortfolioSVG />
                </div>
              </div>
              <div className="project_description">
                <br />
                  <p>
                  You are here! Portfolio Site was created as my personal showcase. The design of the website is inspired by my passion for vinyl records and classic popular music. Please stay a while and take a look around. 
                  <br />
                  <br />
                  Tech Stack: React, GSAP, Gatsby
                  </p>
              </div>
            </div>
            <div className="project_container">
              <div className="project_logo_container">
                <div id="epsilon_logo_container">
                  <img id="epsilon_logo" src={epsilon} style={{width: '100%'}} />
                </div>
              </div>
              <div className="project_description">
                <br />
                  <p>
                  By day, I work as a Creative Technologist on Epsilon’s Creative Technology team. I use JavaScript, HTML, CSS, and GSAP in order to create cross-platform, interactive digital marketing campaigns and solutions. Other responsibilities include integrating third party APIs and libraries, exploring opportunities for product elevation or framework improvements, and implementing viable solutions. As part of an international team, Git work flow is heavily relied upon. My work quality has been recognized through top client assignments including Walgreens, Ulta, and Urban Outfitters.
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
