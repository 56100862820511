import React, {useEffect, useRef, useState} from 'react'

export const WorkSVG = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    const styleA = {
        fill: 'rgb(239,162,38)',
        fillRule: 'nonzero'
    }



      return (
        <svg width="100%" height="100%" viewBox="0 0 739 99" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle}>
            <g transform="matrix(1,0,0,1,-692,-1366)">
                <g transform="matrix(0.437931,0,0,0.721425,372.945,114.753)">
                    <g transform="matrix(196.023,0,0,196.023,720.888,1868.72)">
                        <path d="M0.047,-0.667L0.047,-0L0.734,-0L0.734,-0.17L0.26,-0.17L0.26,-0.667L0.047,-0.667Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,864.768,1868.72)">
                        <path d="M0.221,-0.325C0.226,-0.368 0.239,-0.372 0.366,-0.372C0.473,-0.372 0.487,-0.362 0.487,-0.288L0.487,-0.263L0.479,-0.263C0.451,-0.293 0.427,-0.3 0.345,-0.3C0.215,-0.3 0.169,-0.297 0.126,-0.285C0.057,-0.267 0.025,-0.22 0.025,-0.135C0.025,-0.023 0.074,0.012 0.231,0.012C0.346,0.012 0.391,0.008 0.42,-0.004C0.452,-0.017 0.471,-0.034 0.486,-0.065L0.493,-0.065L0.493,-0L0.663,-0L0.663,-0.285C0.663,-0.371 0.653,-0.407 0.623,-0.439C0.583,-0.479 0.547,-0.486 0.392,-0.486C0.25,-0.486 0.168,-0.477 0.127,-0.458C0.072,-0.431 0.049,-0.394 0.044,-0.325L0.221,-0.325ZM0.339,-0.2C0.414,-0.2 0.442,-0.199 0.453,-0.195C0.473,-0.188 0.484,-0.174 0.484,-0.155C0.484,-0.113 0.449,-0.101 0.324,-0.101C0.229,-0.101 0.202,-0.112 0.202,-0.15C0.202,-0.189 0.231,-0.2 0.339,-0.2Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1001.98,1868.72)">
                        <path d="M0.04,-0.667L0.04,-0L0.207,-0L0.207,-0.062L0.216,-0.062C0.251,-0.003 0.296,0.012 0.428,0.012C0.55,0.012 0.616,-0.002 0.655,-0.037C0.695,-0.072 0.707,-0.12 0.707,-0.238C0.707,-0.361 0.693,-0.407 0.647,-0.442C0.606,-0.473 0.54,-0.486 0.423,-0.486C0.305,-0.486 0.26,-0.469 0.225,-0.41L0.217,-0.41L0.217,-0.667L0.04,-0.667ZM0.374,-0.353C0.422,-0.353 0.465,-0.348 0.488,-0.34C0.52,-0.327 0.53,-0.305 0.53,-0.242C0.53,-0.183 0.523,-0.157 0.503,-0.143C0.484,-0.129 0.441,-0.122 0.377,-0.122C0.33,-0.122 0.285,-0.127 0.263,-0.134C0.227,-0.146 0.217,-0.168 0.217,-0.235C0.217,-0.296 0.225,-0.32 0.25,-0.335C0.27,-0.347 0.312,-0.353 0.374,-0.353Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1145.86,1868.72)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1287.59,1868.72)">
                        <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.196C0.217,-0.269 0.222,-0.295 0.239,-0.318C0.256,-0.34 0.289,-0.352 0.329,-0.352C0.402,-0.352 0.422,-0.333 0.422,-0.263L0.589,-0.263L0.589,-0.295C0.589,-0.437 0.541,-0.485 0.398,-0.485C0.299,-0.485 0.249,-0.462 0.216,-0.402L0.207,-0.402L0.207,-0.473L0.04,-0.473Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1406.18,1868.72)">
                        <path d="M0.025,-0.151C0.026,-0.096 0.038,-0.063 0.067,-0.037C0.109,0.002 0.168,0.012 0.34,0.012C0.614,0.012 0.678,-0.019 0.678,-0.154C0.678,-0.26 0.626,-0.3 0.489,-0.301C0.307,-0.302 0.276,-0.303 0.247,-0.305C0.219,-0.307 0.205,-0.317 0.205,-0.336C0.205,-0.366 0.239,-0.375 0.35,-0.375C0.41,-0.375 0.446,-0.371 0.461,-0.361C0.467,-0.357 0.469,-0.354 0.473,-0.339L0.653,-0.339C0.649,-0.396 0.64,-0.421 0.615,-0.441C0.575,-0.475 0.516,-0.485 0.355,-0.485C0.231,-0.485 0.148,-0.478 0.11,-0.463C0.054,-0.442 0.028,-0.398 0.028,-0.325C0.028,-0.224 0.084,-0.182 0.218,-0.18C0.392,-0.18 0.436,-0.179 0.46,-0.177C0.486,-0.174 0.501,-0.161 0.501,-0.142C0.501,-0.108 0.468,-0.098 0.353,-0.098C0.242,-0.098 0.208,-0.11 0.205,-0.151L0.025,-0.151Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1592.99,1868.72)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1734.72,1868.72)">
                        <path d="M0,-0.346L0.091,-0.346L0.091,-0L0.268,-0L0.268,-0.346L0.442,-0.346L0.442,-0.473L0.268,-0.473C0.27,-0.547 0.287,-0.56 0.381,-0.56C0.392,-0.56 0.395,-0.56 0.428,-0.559L0.428,-0.676C0.39,-0.678 0.372,-0.679 0.339,-0.679C0.221,-0.679 0.176,-0.669 0.138,-0.634C0.102,-0.601 0.094,-0.574 0.091,-0.473L0,-0.473L0,-0.346Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1868.99,1868.72)">
                        <path d="M0.047,-0.667L0.047,-0L0.734,-0L0.734,-0.17L0.26,-0.17L0.26,-0.667L0.047,-0.667Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2012.87,1868.72)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2153.62,1868.72)">
                        <path d="M-0.013,-0.473L0.22,-0L0.449,-0L0.682,-0.473L0.481,-0.473L0.335,-0.137L0.188,-0.473L-0.013,-0.473Z" style={styleA} />
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2283.38,1868.72)">
                        <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleA} />
                    </g>
                </g>
            </g>
        </svg>

    )
}
